import React from "react";
import { Link } from "gatsby";

// React
import { useState, useEffect } from "react";
import { AdvancedImage, responsive } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";

// Heroicons
import {
  ArrowNarrowRightIcon,
  ArrowNarrowDownIcon,
} from "@heroicons/react/outline";
import { PlayIcon } from "@heroicons/react/solid";

// SVGs
import AritaeLogo from "../images/logo/logo.inline.svg";
import AritaeLogoLetters from "../images/logo/logo-letters.inline.svg";

// Shared Components
import Footer from "../components/shared/footer/Footer";
import Header from "../components/shared/header/Header";
import TextModal from "../components/shared/modal/Modal";
import LoadingButton from "../components/shared/loading-button/LoadingButton";
import Seo from "../components/seo";
import VideoModal from "../components/shared/video-modal/VideoModal";

// Service
import { contactSubmission } from "../services/contactAPI";

export default function About() {
  const [isOpen, setIsOpen] = useState(false);
  const [isJerryOpen, setIsJerryOpen] = useState(false);
  const [icon, setIcon] = useState("");
  const [description, setDescription] = useState("");
  const [largeScreen, setLargeScreen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  //ADD LISTENER TO CHECK THE SCREEN SIZE
  function checkScreenSize() {
    if (window.innerWidth <= 768) {
      setLargeScreen(false);
    } else {
      setLargeScreen(true);
    }
  }

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  // Create a Cloudinary instance and set your cloud name.
  const cld = new Cloudinary({
    cloud: {
      cloudName: "kirkwork",
    },
  });
  // define our cloudinary images
  const Championship = cld
    .image(
      "v1648058730/Aritae/About/about-boys-soccer-championship-winners-2021-nysphsaa_njpa9m.jpg"
    )
    .quality("auto");
  const ChampionshipMobile = cld
    .image(
      "v1648058730/Aritae/About/about-boys-soccer-championship-nysphsaa-2021-mobile_oyzuvn.jpg"
    )
    .quality("auto");
  const Phones = cld
    .image("v1646061797/Aritae/About/about-phones-example_fkvixv.png")
    .quality("auto");

  const Jerry = cld
    .image("v1652722586/Aritae/About/jerry-video-placeholder_fytyip.jpg")
    .quality("auto");
  const Karen = cld
    .image("v1646061350/Aritae/About/about-karen_fgoeje.jpg")
    .quality("auto");

  const Maddie = cld
    .image("v1663528487/Aritae/About/maddy-square_xnb2kl.png")
    .quality("auto");

  const Peter = cld
    .image("v1649340009/Aritae/About/peter-square_zqkkxa.jpg")
    .quality("auto");

  const Heather = cld
    .image("v1650471583/Aritae/About/Heather-square_qkt7a2.jpg")
    .quality("auto");

  const VideoCall = cld
    .image("v1647457286/Aritae/About/about-academy-section_u8r8kp.jpg")
    .quality("auto");
  const JerryCoach = cld
    .image("v1649164774/Aritae/About/jerry-coach_drusnr.jpg")
    .quality("auto");
  const JerryBusiness = cld
    .image("v1649274663/Aritae/About/Jerry-23_sn8nle.jpg")
    .quality("auto");
  const JerryFamily = cld
    .image("v1649439349/Aritae/About/Family_Shot_vamwen.jpg")
    .quality("auto");
  const JerryAthelete = cld
    .image("v1652723070/Aritae/About/jerry-club-logo_iqhuyz.jpg")
    .quality("auto");
  const JerryVideoPlaceholder = cld
    .image("v1652722586/Aritae/About/jerry-video-placeholder_fytyip.jpg")
    .quality("auto");

  //email sent from contact form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const email = event.target.email.value;

    let postBody = JSON.stringify({
      Email: email,
      PageName: "About",
    });

    contactSubmission(postBody).then(async (response) => {
      const data = await response;
      if (data.dbSuccess === true && data.endUserEmailSuccess === true) {
        // modal success
        setIsOpen(true);
        setIcon("success");
        setDescription(
          "Thanks for your interest in ARITAE! Please check your inbox for updates."
        );
        setLoading(false);
        event.target.reset();
      } else {
        // modal error
        setIsOpen(true);
        setIcon("failure");
        setDescription("Something went wrong. Please try again.");
        setLoading(false);
        event.target.reset();
      }
    });
  };

  // Modal
  function toggleModalOpen() {
    setIsOpen(!isOpen);
  }

  // Second Modal
  function toggleJerryModalOpen() {
    setIsJerryOpen(!isJerryOpen);
  }

  // Video Modal
  const toggleVideoModal = () => {
    setIsVideoModalOpen(!isVideoModalOpen);
  };

  return (
    <div id="root">
      {/* Header Video Modal */}
      <VideoModal
        isOpen={isVideoModalOpen}
        ariaHideApp={false}
        close={toggleVideoModal}
        link="https://res.cloudinary.com/kirkwork/video/upload/v1649162723/Aritae/Home/header-video_f9uvso.mp4"
      />
      <Header />
      <Seo title="About" description="Learn about ARITAE and its founder." />
      <div className="relative bg-gray-100">
        {/* Modal */}
        <TextModal
          isOpen={isOpen}
          close={toggleModalOpen}
          icon={icon}
          description={description}
        />
        {/* START HERO SECTION */}
        <div className="h-screen mb-24 relative">
          <div className="absolute inset-0">
            <div className="absolute flex-1 top-0 inset-0">
              <video
                autoPlay={true}
                muted
                playsInline
                loop
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              >
                <source
                  src="https://res.cloudinary.com/kirkwork/video/upload/v1652714345/Aritae/Shared/About-Header_a4qvpo.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
          <div className="relative">
            <div className="flex flex-col h-screen items-center justify-center">
              <h1 className="text-center text-4xl font-extrabold sm:text-5xl lg:text-6xl">
                <span className="block text-white text-2xl pb-6">ABOUT</span>
              </h1>
              <div
                className={"flex flex-col items-center justify-center my-12"}
              >
                <AritaeLogo
                  fill={"white"}
                  className={"h-32 w-auto mx-auto pb-2"}
                />
                <AritaeLogoLetters
                  fill={"white"}
                  className={"h-auto w-48 mx-auto"}
                />
              </div>
              <p className={"text-white text-center font-bold text-4xl mt-4"}>
                LOVE. HAPPINESS. SIGNIFICANCE. SUCCESS.
              </p>
              <div className="hidden lg:flex mt-10 max-w-sm mx-auto justify-center">
                <ArrowNarrowDownIcon className={"h-7 text-white"} />
              </div>
            </div>
          </div>
        </div>
        {/* END HERO SECTION */}

        {/* START Aritae Paragraph */}
        <div className={"relative pt-12"}>
          <div
            className={
              "flex flex-col mx-auto px-16 items-center justify-center space-y-10"
            }
          >
            <h2 className={"text-slate-600 text-center text-4xl px-12 pb-4"}>
              WHAT IS <b>ARITAE</b>?
            </h2>
            <p
              className={
                "text-slate-600 leading-normal text-xl max-w-3xl text-center"
              }
            >
              ARITAE is a community of like-hearted and like-minded individuals
              with an inspirational mindset that helps guide people to find the
              clarity, focus and power to unleash their potential, become self-
              leaders, and allow them to unleash their potential, become
              self-leaders, and live a life filled with ARITAE’s 4 Tenants:
              Love, Happiness, Significance, and Success.
            </p>
          </div>
        </div>
        {/* END Aritae Paragraph */}

        {/* START Jerry Video SECTION */}
        <div className="">
          <div
            className={"max-w-7xl mx-auto pb-12 lg:py-12 my-24 px-8 lg:px-16"}
          >
            <div className={"relative grid grid-cols-1 lg:grid-cols-2 gap-10"}>
              {/* First section */}
              <div
                className={
                  "relative flex justify-center items-center max-w-lg mx-auto order-2 lg:order-2"
                }
              >
                <div className="lg:py-8">
                  <div className="p-4">
                    <h1 className="text-right lg:text-4xl font-extrabold tracking-tight sm:text-2xl">
                      <span className="block text-slate-600">
                        JERRY MORRISSEY
                      </span>
                    </h1>
                    <p className="mt-2 text-mustard text-md font-bold text-right">
                      FOUNDER, CEO, & CHIEF INSPIRATIONAL OFFICER
                    </p>
                    <br />
                    <div className="flex flex-col space-y-4">
                      <p className="text-right font-serif text-black text-md">
                        At ARITAE we provide a platform to build a community and
                        train ARITAE coaches who guide young people to develop
                        their emotional and social intelligence, increase their
                        self- awareness so they can explore who they are, what
                        they want to achieve, and understand why they are
                        choosing this path to success.
                      </p>
                      <p className="text-right font-serif text-black text-md">
                        ARITAE is more than a program though, it is a lifestyle
                        rooted in love, happiness, significance, and success.
                        What our players learn here will become part of their
                        identity, helping them re-focus and re-prioritize even
                        when life becomes more complicated and challenging. The
                        ARITAE community of like-hearted and like-minded people
                        will continue to offer them a strong sense of belonging,
                        hope, and faith, keeping them connected to the
                        inspirational love that will allow them to achieve their
                        potential.
                      </p>
                    </div>
                    <div className="flex justify-end text-slate-600 font-bold underline pt-6 underline-offset-4 decoration-2 decoration-slate-600 hover:underline-offset-2">
                      <button
                        onClick={toggleJerryModalOpen}
                        className="cursor-pointer"
                      >
                        Read More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* Second section */}
              <div
                className={
                  "relative h-full w-full max-w-sm md:max-w-lg lg:max-w-none mx-auto order-1 lg:order-2"
                }
              >
                <div className={"relative h-full w-full"}>
                  <div
                    className={
                      "absolute inset-3 -right-3 -bottom-3 bg-slate-600"
                    }
                  />
                  <div className={"absolute inset-0 z-20"}>
                    <div className="relative flex h-full justify-center items-center">
                      <button onClick={toggleVideoModal}>
                        <PlayIcon className="h-24 w-24 text-white opacity-80 hover:opacity-100" />
                      </button>
                    </div>
                  </div>
                  <div
                    className={
                      "relative h-full w-full justify-center items-center"
                    }
                  >
                    <AdvancedImage
                      plugins={[responsive(200)]}
                      cldImg={JerryVideoPlaceholder}
                      alt="CEO jerry morrissey under an awning near a lake"
                      className="h-full w-full object-cover object-top aspect-square"
                    />
                    <div
                      className={"absolute inset-0 opacity-25 bg-stone-500"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal */}
        <TextModal
          isOpen={isJerryOpen}
          ariaHideApp={false}
          title={"JERRY MORRISSEY"}
          close={toggleJerryModalOpen}
        />
        {/* END Jerry Video Section */}

        {/* START About JERRY Section */}
        <div className="bg-mustard bg-opacity-10">
          <div className="max-w-7xl py-24 mx-auto ">
            <h4 className="flex justify-start underline underline-offset-4 mx-12 mb-6 font-bold text-xl text-slate-600 decoration-mustard">
              A LITTLE ABOUT JERRY
            </h4>
            <div className="grid grid-cols-1 md:grid-cols-4 mx-12 gap-6 mb-8">
              <div>
                <div className="relative group hidden md:block w-full aspect-w-1 aspect-h-1 bg-gray-100 overflow-hidden">
                  <AdvancedImage
                    plugins={[responsive({ steps: 200 })]}
                    cldImg={JerryFamily}
                    alt="ARITAE CEO and Chief Inspirational Officer Jerry Morrissey and his family in front of their barn in the snow taking a smiling family photo."
                    className="h-full w-full object-cover object-top"
                  />
                </div>
                <p className="mt-2 text-slate-600 text-md font-bold text-left">
                  FATHER OF FOUR
                </p>
                <p className="text-left font-serif text-black text-sm">
                  Growing up with nine siblings in a small village inspired
                  Jerry to start his own family in that same lakeside village.
                  Now a father of four children, Jerry has had the joy and
                  privilege of watching and coaching his children in their
                  athletic, academic, emotional and social development. Jerry
                  lives in Skaneateles with his wife Kathleen and their precious
                  dog Posey.
                </p>
              </div>
              <div>
                <div className="relative group hidden md:block w-full aspect-w-1 aspect-h-1 bg-gray-100 overflow-hidden">
                  <AdvancedImage
                    plugins={[responsive({ steps: 200 })]}
                    cldImg={JerryAthelete}
                    alt="ARITAE CEO and Chief Inspirational Officer Jerry Morrissey Playing Hockey as a professional Athlete"
                    className="h-full w-full object-cover object-top"
                  />
                </div>
                <p className="mt-2 text-slate-600 text-md font-bold text-left">
                  PROFESSIONAL ATHLETE
                </p>
                <p className="text-left font-serif text-black text-sm">
                  Jerry started playing hockey at the age of 5 and finished his
                  career playing professional ice hockey in Europe. He also
                  played baseball and soccer throughout his youth. However, to
                  reach the level of professional hockey, Jerry relied on
                  clarity, focus, and personal power to fulfill his dreams.
                </p>
              </div>
              <div>
                <div className="relative group hidden md:block w-full aspect-w-1 aspect-h-1 bg-gray-100 overflow-hidden">
                  <AdvancedImage
                    plugins={[responsive({ steps: 200 })]}
                    cldImg={JerryCoach}
                    alt="ARITAE CEO and Chief Inspirational Officer Jerry Morrissey Giving Motivational speech right before a highschool hockey game."
                    className="h-full w-full object-cover object-top"
                  />
                </div>
                <p className="mt-2 text-slate-600 text-md font-bold text-left">
                  EXPERIENCED COACH
                </p>
                <p className="text-left font-serif text-black text-sm">
                  Jerry has coached youth and high school sports for over 30
                  years, eventually creating a self-leadership ice hockey
                  school. His background in psychology and education, 35+ years
                  studying the top minds in personal development and leadership,
                  combined with coaching, led Jerry to develop and write
                  “Coaching for Kids” in 2003. That program has evolved into
                  this ARITAE Self-Leadership Program.
                </p>
              </div>
              <div>
                <div className="relative group hidden md:block w-full aspect-w-1 aspect-h-1 bg-gray-100 overflow-hidden">
                  <AdvancedImage
                    plugins={[responsive({ steps: 200 })]}
                    cldImg={JerryBusiness}
                    alt="ARITAE CEO and Chief Inspirational Officer Jerry Morrissey posing for his realty company head shot."
                    className="h-full w-full object-cover object-top"
                  />
                </div>
                <p className="mt-2 text-slate-600 text-md font-bold text-left">
                  ENTREPRENEUR
                </p>
                <p className="text-left font-serif text-black text-sm">
                  Starting at the age of 18, Jerry started building companies.
                  From a starting his own painting company, to inventing health
                  care products for women, to starting his own Real Estate
                  Company called Finger Lakes Realty Partners which he continues
                  to own and operate with great excitement and pride. Jerry has
                  been in the field of innovation for almost his entire life.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* END About JERRY Section */}

        {/* START ACADEMY */}
        <div className="">
          <div className={"max-w-7xl mx-auto py-12 my-12 lg:my-24 px-16"}>
            <div className={"relative grid grid-cols-1 lg:grid-cols-2 gap-10"}>
              {/* First section */}

              <div
                className={
                  "relative h-full w-full max-w-sm md:max-w-lg lg:max-w-none mx-auto"
                }
              >
                <div className={"relative h-full w-full"}>
                  <div
                    className={
                      "absolute inset-3 -right-3 -bottom-3 bg-slate-600"
                    }
                  />
                  <div
                    className={
                      "relative h-full w-full justify-center items-center"
                    }
                  >
                    <AdvancedImage
                      plugins={[responsive(200)]}
                      cldImg={VideoCall}
                      alt="young woman stretches on a field with phone on ground showing ARITAE mobile app"
                      className="h-full w-full object-cover object-top aspect-square"
                    />
                  </div>
                </div>
              </div>
              {/* Second section */}
              <div
                className={
                  "relative flex justify-center items-center -mt-16 md:mt-0 md:p-10"
                }
              >
                <div>
                  <h1 className="text-left text-3xl mt-20 md:mt-6 font-extrabold tracking-tight">
                    <span className="block text-slate-600">
                      THE ARITAE SELF-LEADERSHIP ACADEMY
                    </span>
                  </h1>
                  <br />
                  <p className="text-left font-serif text-black text-md">
                    At the Academy, we believe that every young person has the
                    potential to be an asset in this world and that the need to
                    invest in this “human potential” is immeasurable. We take a{" "}
                    <b>proactive approach to personal growth</b> by utilizing
                    the ARITAE program and the ARITAE Coach to guide young
                    people to find the clarity, focus, and power needed to reach
                    their potential, become self-leaders, and live a life filled
                    with the level of love, happiness, significance, and success
                    that they desire.
                  </p>
                  <br></br>
                  <div className={"flex flex-row gap-10"}>
                    <Link
                      to={"/academy"}
                      className={"flex flex-row items-center gap-5 hover:gap-3"}
                    >
                      <p
                        className={
                          "text-slate-600 font-bold underline underline-offset-4 decoration-2 decoration-slate-600 hover:underline-offset-2"
                        }
                      >
                        Discover The Academy
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* END ACADEMY */}

        {/* START TEAM Section */}
        <div className="">
          <div className={"relative py-12 lg:py-24 px-16"}>
            <div className={"pb-12 lg:pb-24"}>
              <div className={"flex items-center"}>
                <h1
                  className={
                    "mx-auto text-slate-600 max-w-xl text-center text-4xl"
                  }
                >
                  THE <span className="font-extrabold">TEAM</span>
                </h1>
              </div>
            </div>
            <div
              className={
                "relative mx-auto max-w-sm md:max-w-lg grid grid-cols-1 lg:grid-cols-5 gap-3 lg:max-w-7xl"
              }
            >
              <div className={"relative"}>
                <div className={"aspect-w-1 aspect-h-1"}>
                  <div className="aspect-w-1 aspect-h-1">
                    <AdvancedImage
                      plugins={[responsive({ steps: 200 })]}
                      cldImg={Jerry}
                      alt="ARITAE CEO and Chief Inspirational Officer Jerry Morrissey posing for his photo as a real estate agent."
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <div className={"absolute inset-0 opacity-50 bg-stone-500"} />
                  <div>
                    <div
                      className={
                        "absolute bottom-8 left-4 right-4 text-white text-left"
                      }
                    >
                      <p className="font-extrabold text-3xl">Jerry</p>
                      <p className="font-extrabold text-3xl">Morrissey</p>
                      <p className="font-regular text-sm">Founder, CEO</p>
                      <p className="font-regular text-sm">
                        & Chief Inspiration Officer
                      </p>
                    </div>
                  </div>
                </div>
                <p className="mt-2   text-sm font-medium text-gray-900 pointer-events-none pb-5">
                  Jerry has been studying and working in the field of personal
                  growth and leadership development for over 35 years. ARITAE is
                  the culmination of everything he has learned and keeps him
                  inspired every day.
                </p>
              </div>

              <div className={"relative"}>
                <div className={"aspect-w-1 aspect-h-1"}>
                  <div className="aspect-w-1 aspect-h-1">
                    <AdvancedImage
                      plugins={[responsive({ steps: 200 })]}
                      cldImg={Heather}
                      alt="Heather Gaglione standing on a pier."
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <div className={"absolute inset-0 opacity-50 bg-stone-500"} />
                  <div>
                    <div
                      className={
                        "absolute bottom-8 left-4 right-4 text-white text-left"
                      }
                    >
                      <p className="font-extrabold text-3xl">Heather</p>
                      <p className="font-extrabold text-3xl">Gaglione</p>
                      <p className="font-regular text-sm">
                        Chief Financial Officer
                      </p>
                    </div>
                  </div>
                </div>
                <p className="mt-2   text-sm font-medium text-gray-900 pointer-events-none pb-5">
                  Heathers diverse background in finance and business, combined
                  with her love for her family inspire her every day. Supporting
                  her three boys in their academic and athletic pursuits,
                  Heather believes that the four tenets of ARITAE will lead them
                  to achieve their dreams.
                </p>
              </div>

              <div className={"relative"}>
                <div className={"aspect-w-1 aspect-h-1"}>
                  <div className="aspect-w-1 aspect-h-1">
                    <AdvancedImage
                      plugins={[responsive({ steps: 200 })]}
                      cldImg={Peter}
                      alt="Peter Kirk smiling while on white sandy beach in a striped button up shirt."
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <div className={"absolute inset-0 opacity-50 bg-stone-500"} />
                  <div>
                    <div
                      className={
                        "absolute bottom-8 left-4 right-4 text-white text-left"
                      }
                    >
                      <p className="font-extrabold text-3xl">Peter</p>
                      <p className="font-extrabold text-3xl">Kirk</p>
                      <p className="font-regular text-sm">
                        Director of Technology
                      </p>
                    </div>
                  </div>
                </div>
                <p className="mt-2   text-sm font-medium text-gray-900 pointer-events-none pb-5">
                  Peter is a rockstar software developer with a diverse
                  background. From the design of large corporate systems to
                  mobile application development for iOS and Android, Peter has
                  done it all!
                </p>
              </div>

              <div className={"relative"}>
                <div className={"aspect-w-1 aspect-h-1"}>
                  <div className="aspect-w-1 aspect-h-1">
                    <AdvancedImage
                      plugins={[responsive({ steps: 200 })]}
                      cldImg={Maddie}
                      alt="Maddy Morrissey coaching on the field with a lacrosse stick in hand."
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <div className={"absolute inset-0 opacity-50 bg-stone-500"} />
                  <div>
                    <div
                      className={
                        "absolute bottom-8 left-4 right-4 text-white text-left"
                      }
                    >
                      <p className="font-extrabold text-3xl">Maddy</p>
                      <p className="font-extrabold text-3xl">Buss</p>
                      <p className="font-regular text-sm">
                        Director of Coaching
                      </p>
                    </div>
                  </div>
                </div>
                <p className="mt-2   text-sm font-medium text-gray-900 pointer-events-none pb-5">
                  A 4-year member of the Women's Varsity Lacrosse Team at Duke
                  University, Maddy now coaches female athletes at all levels.
                  Her passion is creating opportunities for young women to
                  achieve their goals, by way of competing in lacrosse. It is my
                  pleasure to help many individuals with their college
                  recruitment processes.
                </p>
              </div>

              <div className={"relative"}>
                <div className={"aspect-w-1 aspect-h-1"}>
                  <div className="aspect-w-1 aspect-h-1">
                    <AdvancedImage
                      plugins={[responsive({ steps: 200 })]}
                      cldImg={Karen}
                      alt="Karen Dunphey smiling and posing for photo infront of a wooden backdrop."
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <div className={"absolute inset-0 opacity-50 bg-stone-500"} />
                  <div>
                    <div
                      className={
                        "absolute bottom-8 left-4 right-4 text-white text-left"
                      }
                    >
                      <p className="font-extrabold text-3xl">Karen</p>
                      <p className="font-extrabold text-3xl">Dunphy</p>
                      <p className="font-regular text-sm">
                        Director of Marketing
                      </p>
                    </div>
                  </div>
                </div>
                <p className="mt-2   text-sm font-medium text-gray-900 pointer-events-none">
                  Karen has been working in creative spaces since her work as a
                  Creativity and Innovation Consultant in Cambridge, MA. She
                  also has decades of teaching and administrative experience in
                  6-12 schools. The proud mom of 3 athletes, the 4 tenets of
                  ARITAE are drivers in their happiness and success.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* END TEAM Section */}

        {/* START Digital Section */}
        <div className="bg-slate-600 my-24">
          <div className={"max-w-7xl mx-auto px-6 md:px-16"}>
            <div className={"relative grid grid-cols-1 lg:grid-cols-2 gap-10"}>
              {/* First section */}
              <div className={"relative order-2 lg:order-1 pt-12"}>
                <div className={"relative h-auto w-full"}>
                  <div
                    className={
                      "relative max-w-sm md:max-w-lg lg:max-w-none mx-auto h-full w-full justify-center items-center bg-slate-600"
                    }
                  >
                    <AdvancedImage
                      plugins={[responsive({ steps: 200 })]}
                      cldImg={Phones}
                      alt="two cell phones each showing different screens of the ARITAE mobile application"
                      className=""
                    />
                  </div>
                </div>
              </div>
              {/* Second section */}
              <div
                className={
                  "relative flex justify-center items-center order-1 lg:order-2 "
                }
              >
                <div className="">
                  <h1 className="text-left text-4xl tracking-tight ">
                    <span className="block text-white bg-slate-600 pt-20 lg:pt-12">
                      HISTORY <b>&</b>
                      <br /> DIGITAL <br />
                      <b>TRANSFORMATION</b>
                    </span>
                  </h1>
                  <br />
                  <p className="text-left font-serif text-white text-md pb-0 lg:pb-4">
                    ARITAE began as a self-leadership program in the early
                    2000’s, which Jerry developed called Coaching for Kids.
                    After years of working with young people, and continuously
                    improving the program, Jerry and his team are re-launching
                    the ARITAE.com website and mobile app as part of the new
                    digital platform. The platform helps ARITAE players and
                    coaches connect as members of the ARITAE community. Players
                    can now complete the ARITAE self-leadership program on their
                    smartphones. ARITAE coaches leverage the platform to guide,
                    inspire, and communicate with their players, thus expanding
                    their reach and footprint beyond traditional coaching norms.
                    ARITAE uses valuable metrics and data analytics to
                    consistently improve the ARITAE Self-Leadership Academy
                    platform and enhance the ARITAE community’s ability to
                    inspire young people, to live a life filled with LOVE,
                    HAPPINESS, SIGNIFICANCE, and SUCCESS.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* END Digital Section */}

        {/* START FAQ Pragraph */}
        <div className={"relative py-24"}>
          <div
            className={
              "flex flex-col mx-auto px-16 items-center justify-center space-y-10"
            }
          >
            <p
              className={
                "text-slate-600 font-bold text-4xl max-w-2xl text-center"
              }
            >
              Still have more questions?
            </p>
            <Link
              to={"/faq"}
              className={"flex flex-row items-center gap-5 hover:gap-3"}
            >
              <p
                className={
                  "text-slate-600 font-bold underline underline-offset-4 decoration-2 decoration-slate-600 hover:underline-offset-2"
                }
              >
                Visit Our FAQ Page
              </p>
            </Link>
          </div>
        </div>
        {/* END FAQ Paragraph */}

        {/* START Become a Section */}
        <div className="max-w-7xl mx-auto pt-24 pb-12 px-16">
          <div className="grid grid-rows-3 text-4xl divide-y divide-gray-300">
            <Link to={"/academy/players"}>
              <div className={"flex flex-row py-8 justify-between hover:mr-2"}>
                <p className={"text-mustard"}>
                  BECOME A <b>PLAYER</b>
                </p>
                <ArrowNarrowRightIcon className={"h-8 inline text-mustard"} />
              </div>
            </Link>

            <Link to={"/academy/coaches"}>
              <div className={"flex flex-row py-8 justify-between hover:mr-2"}>
                <p className={"text-navy"}>
                  BECOME A <b>COACH</b>
                </p>
                <ArrowNarrowRightIcon className={"h-8 inline text-navy"} />
              </div>
            </Link>

            <Link to={"/academy/parents"}>
              <div className={"flex flex-row py-8 justify-between hover:mr-2"}>
                <p className={"text-forest"}>
                  ARE YOU A <b>PARENT</b>?
                </p>
                <ArrowNarrowRightIcon className={"h-8 inline text-forest"} />
              </div>
            </Link>
          </div>
        </div>
        {/* END Become a Section*/}

        {/* START Sign Up Form */}
        <div className={"flex h-screen justify-center items-center mt-24"}>
          <div className="absolute z-10 flex h-screen w-screen overflow-hidden">
            <div className={"absolute inset-0 opacity-75 bg-stone-500"} />
            <AdvancedImage
              cldImg={largeScreen ? Championship : ChampionshipMobile}
              plugins={[responsive({ steps: 200 })]}
              alt="group of young players and their coaches celebrating 2021 nysphsaa soccer championship"
              className="w-full h-full object-cover"
            />
          </div>

          <div className="relative z-20 mx-auto max-w-3xl text-center md:px-5">
            <div className="py-10">
              <h4 className="text-4xl font-extrabold text-white">
                ARE YOU READY TO GET STARTED?
              </h4>
            </div>

            <form
              className="space-y-6 row-auto py-5 p-4  max-w-3xl xs:m-12 md:m-0"
              action="#"
              method="POST"
              onSubmit={handleSubmit}
            >
              <div className="mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center justify-between">
                  <div className="mt-1 md:col-span-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      placeholder="Email"
                      required
                      className="text-center md:text-left w-full px-5 py-3 text-gray-500 border-transparent placeholder-gray-500 bg-gray-100 font-sm md:font-md"
                    />
                  </div>

                  <div className="mt-1">
                    {loading ? (
                      <LoadingButton type={"email"} />
                    ) : (
                      <button
                        type="submit"
                        className="w-full flex justify-center px-5 py-3 border border-transparent rounded shadow-sm text-sm text-white font-bold bg-black hover:bg-gray-800"
                      >
                        SIGN UP
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* END Sign Up Form */}
      </div>
      <Footer />
    </div>
  );
}
