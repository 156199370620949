import React from "react";

// React
import Modal from "react-modal";

//Modal.setAppElement('#root')

export default function VideoModal(props) {
    return (
        <div>
            <Modal
                style={{
                    overlay: { zIndex: 1000 },
                    content: {
                        background: "#000000",
                    },
                }}
                isOpen={props.isOpen}
                //contentLabel="Example Modal"
                parentSelector={() => document.querySelector("#root")}
                onRequestClose={() => {
                    props.close();
                }}
                shouldCloseOnOverlayClick={true}
                ariaHideApp={false}
            >
                <div className="absolute flex-1 top-0 inset-0">
                    <video
                        autoPlay={true}
                        controls={true}
                        playsInline
                        style={{ height: "100%", width: "100%", objectFit: "contain" }}
                    >
                        <track />
                        <source src={props.link} type="video/mp4" />
                    </video>
                    <button
                        className={"absolute top-5 right-5 z-50 bg-white rounded-md py-2 px-5 text-black"}
                        onClick={() => {
                            props.close();
                        }}
                    >
                        X
                    </button>
                </div>
            </Modal>
        </div>
    );
}
